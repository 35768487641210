.align-center, .align-left, .align-right, .align-justify, .display-contents {
  display: block;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-justify {
  text-align: justify;
}

.display-contents {
  display: contents;
}

@media screen and (min-width: 600px) {
  br.mobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  br.desktop {
    display: none;
  }
}

@media screen and (max-width: 1380px) {
  .hero-arrows {
    display: none;
  }
}

@keyframes scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
